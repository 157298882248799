<template>
  <div class="player-wrapper">
    <audio preload="auto" style="display: none" ref="audio" :src="streamSrc" controls></audio>
    <div :class="['player-body', { firstPlayed: firstPlayed }]">
      <div class="main-image">
        <!-- <img :src="fruits[randomIndex]" /> -->
        <img :src="fruit" />
        <!-- <div :class="['player-button-place', { pulser: audioPlayed }]" -->
        <div :class="['player-button-place']"
         @mouseenter="onHoverPlay(true)"
          @mouseleave="onHoverPlay(false)">
          <img :class="['play-img', { 'spin-animation': firstPlayed }]" v-if="!audioPlayed" @click="changeAudioPlay"
            :src="play" />
          <img :class="['pause-img', { 'spin-animation': firstPlayed }]" v-if="audioPlayed" @click="changeAudioPlay"
            :src="pause" />
        </div>
      </div>
      <div class="track">
        <div class="title">{{ track.title }}</div>
        <div class="artist">{{ track.artist }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import fruit from '@/assets/fruits/fruit.svg';
import fruit0 from '@/assets/fruits/fruit0.svg';
import fruit1 from '@/assets/fruits/fruit1.svg';
import fruit2 from '@/assets/fruits/fruit2.svg';
import fruit3 from '@/assets/fruits/fruit3.svg';
import fruit4 from '@/assets/fruits/fruit4.svg';
import fruit5 from '@/assets/fruits/fruit5.svg';
import fruit6 from '@/assets/fruits/fruit6.svg';
import fruit7 from '@/assets/fruits/fruit7.svg';
import fruit8 from '@/assets/fruits/fruit8.svg';
import play from '@/assets/player/play.svg';
import pause from '@/assets/player/pause.svg';


import { ref } from 'vue';


export default {
  name: 'MusicPlayer',
  props: ['streamSrc', 'track', 'firstPlayed'],
  emits: ['onFirstPlayed', 'onHoverPlay'],
  // eslint-disable-next-line vue/no-setup-props-destructure
  setup({ firstPlayed }, ctx) {

    const fruits = [fruit0,fruit1,fruit2,fruit3,fruit4,fruit5,fruit6,fruit7,fruit8]
    var randomIndex = parseInt(Math.random() * fruits.length);


    const audio = ref(null);

    const audioPlayed = ref(false);

    const onHoverPlay = (value) => {
      ctx.emit('onHoverPlay', value);
    }

    const changeAudioPlay = async () => {
      console.log("start - audio fn")
      if (audioPlayed.value) {
        await audio.value.pause();
        audioPlayed.value = false;
        return;
      }
      await audio.value.play()
      audioPlayed.value = true;
      if (!firstPlayed) {
        ctx.emit('onFirstPlayed');
      }

      console.log("end - audio fn");
    }

    return {
      fruit,
      fruits,
      randomIndex,
      audio,
      changeAudioPlay,
      onHoverPlay,
      play,
      pause,
      audioPlayed
    }
  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;500;600;700&family=Barlow:wght@400;700&family=Poppins:wght@400;500;700&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300&display=swap');


.spin-animation {
  animation: spin 0.55s ease-in alternate;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    opacity: 0;
  }



  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.pulser {

  &::before,
  &::after {
    animation: pulse 2s linear infinite;
    border: #fff solid 8px;
    border-radius: 9999px;
    box-sizing: border-box;
    content: ' ';
    height: 140%;
    left: -20%;
    opacity: .6;
    position: absolute;
    top: -20%;
    transform: scale(0.714);
    width: 140%;
    animation: pulse 12s linear infinite, cycle-colors 6s linear infinite;
    z-index: -1;

  }

  &::after {
    animation-delay: 2s;
    animation-delay: 1.5s;
  }



}

@keyframes cycle-colors {
  0% {
    border-color: rgba(255, 255, 255, 0.6);
    opacity: 0.8;
  }

  25% {
    border-color: rgba(255, 255, 255, 0.6);
  }

  50% {
    border-color: rgba(255, 255, 255, 0.6);
  }

  75% {
    border-color: rgba(255, 255, 255, 0.6);
  }

  100% {
    border-color: rgba(255, 255, 255, 0.6);
  }
}

@keyframes pulse {
  from {
    /* opacity: 0.6; */
  }

  to {
    opacity: 0;
    transform: scale(1);
  }
}

.player-wrapper {
  z-index: 3;
  flex: 1;

  * {
    user-select: none;
  }

  // Mobile
  @media screen and (max-width: 360px) {
    /* background: greenyellow; */
    min-height: 100vh;
  }

  // Watch
  @media screen and (max-width: 184px) {
    /* background: orchid; */
  }
}

.player-body {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 5px;
  padding-bottom: 50px;

  &.firstPlayed {
    justify-content: flex-end;

    @media screen and (max-width: 184px) {
      /* justify-content: flex-start; */
    }

    .main-image {
      width: 114px;
      height: 114px;
      margin-bottom: 20px;
      transition: all 0.3s;

      @media screen and (max-width: 184px) {
        width: 58px;
        height: 58px;
      }

      .player-button-place {

        transition: all 0.3s;
        img {
          cursor: pointer;
        }

        // small
        .play-img {
          display: block;
          width: 35px;
          height: 35px;
          margin-left: 10px;
          margin-bottom: 1px;

          transition: all 0.3s;

          @media screen and (max-width: 184px) {
            width: 25px;
            height: 25px;
            margin: 0px;
            margin-left: 5px;
            margin-top: 4px;
          }
        }

        .pause-img {
          width: 25px;
          height: 25px;

          transition: all 0.3s;

          @media screen and (max-width: 184px) {
            width: 30px;
            height: 30px;

          }
        }
      }

      &:hover {
      .player-button-place {
        transition: all 0.3s;
        // small hover
        .play-img {
          width: 40px;
          height: 40px;
          margin-left: 13px;
          margin-bottom: 2px;
          transition: all 0.3s;
        }

        .pause-img {
          width: 30px;
          height: 30px;
          transition: all 0.3s;
        }
      }
    }
    }

    .track {
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media screen and (max-width: 184px) {
        gap: 4px;
      }

      .title {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 31px;
        line-height: 35px;
        /* identical to box height, or 67% */
        text-align: center;
        color: #FFFFFF;

        @media screen and (max-width: 360px) {
          font-size: 28px;
          line-height: 24px;
        }

        @media screen and (max-width: 184px) {
          font-size: 18px;
          line-height: 18px;
        }
      }

      .artist {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: #FFFFFF;

        @media screen and (max-width: 360px) {
          font-size: 18px;
          line-height: 21px;
        }

        @media screen and (max-width: 184px) {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }

  .main-image {
    width: 237px;
    height: 237px;
    display: flex;
    margin-inline: auto;
    position: relative;

    .player-button-place {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;


      .play-img {
        width: 80px;
        height: 80px;
        cursor: pointer;
        margin-left: 20px;
        margin-top: 10px;

        @media screen and (max-width: 900px) {
          display: block;
        }

        // Mobile
        @media screen and (max-width: 360px) {
          width: 88px;
          height: 88px;
          display: block;
        }

        // Watch
        @media screen and (max-width: 184px) {
          width: 68px;
          height: 68px;
          margin-left: 13px;
          display: block;

        }
      }

      &:hover {
        .play-img {
          width: 90px;
        height: 90px;
        margin-left: 20px;
        margin-top: 10px;
        transition: all 0.3s;
        }
      }
    }


    img {
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: 360px) {
      width: 190px;
      height: 190px;
    }

    @media screen and (max-width: 184px) {
      width: 117px;
      height: 117px;
    }


  }

  .track {
    display: none;
  }


}
</style>