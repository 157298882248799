export const unsplashAccessKey = 'uKb6UZ1HTrFG3lb3PJm-jSOo-ntyrCXipbh900PuMiY'
export const unsplashUrl = `https://api.unsplash.com/photos/random?collections=10030526,h_gmPhbdClo&count=1`
//export const unsplashUrl = 'https://api.unsplash.com/collections/10030526'
// /collections/

export const streamUrl = 'https://solid67.streamupsolutions.com:2199/rpc/repadnom/streaminfo.get'

export const sizes = {
  watch: '184px',
  mobile: '360px',
  tabled: '1440px',
  desktop: '1920px',
}

