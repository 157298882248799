<template>
  <HomePage />
</template>

<script>
import HomePage from './components/HomePage.vue';

export default {
  name: 'App',
  components: {
    HomePage
  }
}
</script>

<style lang="scss">
body {
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  background-color: #1B2430;
  color: orange;

  * {
    box-sizing: border-box;
  }
}
</style>
