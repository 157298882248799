<template>
  <div class="wrapper">
    <div :style="{
      backgroundImage: `url(${bgImage})`,
      filter:
        !firstPlayed ? 'blur(4px)' : ''
    }" class="background-image" />
    <div class="overlay-effect" />
    <div class="content">
      <div class="main">

        <div class="logo-place ">
          <img :src="logo" />
        </div>

        <MusicPlayer v-if="stream" @onHoverPlay="onHoverPlay" @onFirstPlayed="onFirstPlayed" :firstPlayed="firstPlayed"
          :streamSrc="streamSrc" :track="track" />

        <div class="footer">
          <div class="logo-place showed-logo">
            <img :src="logo" />
          </div>


          <div class="social-wrapper">
            <img :src="fb" @click="clickOnSocial('https://www.facebook.com/exoticaradio')" />
            <img :src="yt" @click="clickOnSocial('https://www.youtube.com/channel/exoticaradio')" />
            <img :src="ig" @click="clickOnSocial('https://www.instagram.com/exotica.radio/')" />
            <img class="ugs" :src="ugs" @click="clickOnSocial('https://www.undergroundsound.eu')" />
          </div>
          <div class="footer-text">
            Exotica Radio 2023 ©
            <span v-if="imageUser?.link && imageUser?.name">
              Photo by <a :href="imageUser.link"> {{ imageUser.name }} </a> on <a href="https://unsplash.com/">
                Unsplash </a>
            </span>
          </div>
        </div>
      </div>
    

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import { onMounted, ref } from 'vue'
import { streamUrl } from '@/utils/contants';
import MusicPlayer from '@/components/MusicPlayer'
import { unsplashAccessKey, unsplashUrl } from '@/utils/contants'
import logo from '@/assets/logo.svg';
import fb from '@/assets/social/fb.svg'
import ig from '@/assets/social/ig.svg'
import ugs from '@/assets/social/ugs.svg'
import yt from '@/assets/social/yt.svg'

export default {
  name: 'HomePage',
  components: { MusicPlayer },
  setup() {
    const bgImage = ref('');

    const stream = ref({});

    const streamSrc = ref('');

    const tuneinurl = 'proxytuneinurltls';

    const track = ref({
      artist: '',
      title: ''
    })

    const imageUser = ref(null);

    const firstPlayed = ref(false);
    const onFirstPlayed = () => {
      firstPlayed.value = true;
    }

    const isBlurredImage = ref(true);
    const onHoverPlay = (value) => {
      isBlurredImage.value = !value;
    }


    const changeTrack = async () => {
      const tartist = document.querySelector("span[data-type='trackartist']").innerHTML
      const ttitle = document.querySelector("span[data-type='tracktitle']").innerHTML
   
      if (tartist && ttitle){
        track.value = { artist: tartist, title: ttitle };
      //console.log('check track data',{ artist: tartist, title: ttitle });
      }
      
    }

    setInterval(async () => {
      await changeTrack()
    }, 2000)

    const changeStream = async () => {
      const res = await axios.get(streamUrl);
      const data = res.data.data[0]
      console.log(data);
      stream.value = data;
      streamSrc.value = data[tuneinurl];
      track.value = { artist: data.track.artist, title: data.track.title };
    }

    setInterval(async () => {
      await changeBackground()
    }, 65000)

    const changeBackground = async () => {

      try {

        const res = await axios.get(unsplashUrl, {
          headers: {
            'Authorization': `Client-ID ${unsplashAccessKey}`
          }
        })
        const data = res.data[0]

        const image = data.urls.regular
        bgImage.value = image;
        imageUser.value = {
          name: data.user.first_name + ' ' + data.user.last_name,
          link: data.user.links.self
        }
      }
      catch (err) {
        console.error(err)
        bgImage.value = {};
      }
    }

    const clickOnSocial = (url) => {
      window.open(url);
    }

    onMounted(async () => {
      await changeStream()
      await changeBackground();

    })


    return {
      bgImage, stream, streamSrc, logo, track, firstPlayed, onFirstPlayed, isBlurredImage, onHoverPlay
      ,
      fb,
      ig,
      ugs,
      yt,
      clickOnSocial,
      imageUser
    }
  }

}

</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  object-fit: fill;
  position: relative;
  transition: opacity 0.5s ease-in-out;

  img {
    transition: opacity 0.5s ease-in-out;
  }


  .background-image {
    width: 100%;
    height: 100%;
    position: fixed;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    object-fit: fill;
  }
}

.overlay-effect {
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, #3FD3D6, #45D5A1, #FFEA86, #FBF4EA, #EDFF00, #FF5E00, #770053, #150034);
  background-size: 400% 400%;
  animation: gradient 120s ease infinite;
  height: 100vh;
  opacity: 33%;
  z-index: 1;
  position: relative;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.content {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}


.main {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 184px) {
    overflow: auto;
  }

}

.logo-place {
  width: 88px;
  display: flex;
  margin-inline: auto;
  -webkit-filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, .7));
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.7));
  margin-top: 40px;

  &.showed-logo {
    display: flex;
    margin-top: 15px;

    @media screen and (min-width:185px) {
      display: none;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width:184px) {
    /* visibility: hidden; */
    /* margin-top: 20px; */
    display: none;
  }

}

.footer {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 21px;
  /* margin-bottom: 43px; */

  @media screen and (max-width: 184px) {
    min-height: 100vh;
    /* border: 1px solid red; */
    justify-content: space-around;
    /* justify-content: center; */
  }
}

.social-wrapper {
  gap: 24px;
  display: flex;

  img {
    width: 19px;
    height: 19px;
    cursor: pointer;
  }

  img.ugs{
    width: 29px;
    height: 29px;
    margin-top:-5px;
  }

  @media screen and (max-width: 184px) {
    flex-wrap: wrap;
    gap: 0px;

    :first-child {
      margin-bottom: 20px;
    }


    img {
      width: 20px;
      height: 20px;
      flex: 50%;
      /* margin-bottom: 15px; */
    }
  }

}

.footer-text {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  text-align: center;
  max-width: 80%;

  a {
    font-weight: bold;
    color: white;

    @media screen and (max-width: 184px) {
      font-weight: 500;
    }
  }

  @media screen and (max-width: 184px) {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    padding-bottom: 10px;
  }
}
</style>